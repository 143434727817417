@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	background-color: black;

	margin: 0;
	font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Noto Color Emoji';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.background-image {
	background-image: url('./assets/bg.jpg');
	background-position: right center;
	background-repeat: no-repeat;
	min-height: 100vh;
}
